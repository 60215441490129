import { Shift } from "./Shift";
import { Unity } from "../unity";
import { AccountType } from "../account-type/AccountType";
import { UserDto } from "../../network/user/User";
import { Role, UserRoles } from "./Role";
import { FileType } from "rsuite/Uploader";
import { isNil } from "lodash";

export type NewUser = {
  uid: string;
  displayName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  photoFileType?: FileType | null;
  photoUrl?: string | null;
  shiftId?: number | null;
  unityId?: number | null;
  unityIdParent?: number | null;
  accountTypeId?: number | null;
  typeWorker?: number | null;
  roleId?: number | null;
  password?: string;
  disabled?: boolean;
  clientUnity?: Array<number>;
  reason?: string;
};

export type PentrackerUser = UserDto & {
  shift?: Shift | null;
  unity?: Unity | null;
  accountType?: AccountType | null;
  userRole?: Role | null;
  clientUnityArray?: Unity[];
};

export const pentrackerUserAsNewUserData = (user: PentrackerUser): NewUser => {
  return {
    uid: user.uid,
    displayName: user.displayName,
    phoneNumber: user.phoneNumber,
    email: user.email,
    photoUrl: user.photoURL,
    shiftId: user.shiftId,
    unityId: user.unityId,
    unityIdParent: user.unityIdParent,
    accountTypeId: user.accountTypeId,
    typeWorker: user.typeWorker,
    roleId: user.roleId,
    disabled: user.disabled,
    password: "",
    clientUnity: user.clientUnity,
  };
};

export const userWithClaimsAsPentrackerUser = (
  userWithClaims: UserDto,
  unity?: Unity | null,
  shift?: Shift | null,
  role?: Role | null,
  accountType?: AccountType | null,
  clientUnityArray?: Unity[]
): PentrackerUser => {
  return {
    ...userWithClaims,
    unity: unity,
    shift: shift,
    userRole: role,
    accountType: accountType,
    clientUnityArray,
  };
};

export class UserWrapper {
  user!: PentrackerUser;

  uid() {
    return this.user.uid;
  }

  constructor(_user: PentrackerUser) {
    this.user = _user;
  }

  isMainAdmin = () =>
    this.user.roleId === UserRoles.mainadmin ||
    this.user.email === "sistemas@pentagonosegur.com.pe";

  isAdmin = () => this.isMainAdmin() || this.user.roleId === UserRoles.admin;

  isClient = () => this.user.roleId === UserRoles.client;

  hasAssignedRole = () => !isNil(this.user.roleId);

  isManager = () =>
    this.user.roleId === UserRoles.admin ||
    this.user.roleId === UserRoles.mainadmin ||
    this.user.roleId === UserRoles.supervisor ||
    this.user.roleId === UserRoles.control ||
    this.user.email === "sistemas@pentagonosegur.com.pe";

  isOperator = () =>
    this.user.roleId === UserRoles.groupboss ||
    this.user.roleId === UserRoles.CCTV ||
    this.user.roleId === UserRoles.agent;
}
