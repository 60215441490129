// src/components/LogoLoader.tsx
import React, { Suspense, useState, useEffect, useContext } from "react";
import iconMap from "./iconMap";
import { UserAuthContext } from "../../context/UserContext";

interface IconLoaderProps {
  // iconName: string;
  className?: string;
}

const LogoLoader: React.FC<IconLoaderProps> = ({ ...rest }) => {
  const { appUser } = useContext(UserAuthContext);
  const [IconComponent, setIconComponent] = useState<React.ComponentType<
    React.SVGProps<SVGSVGElement>
  > | null>(null);

  useEffect(() => {
    const iconLogo = async () => {
      if (appUser.user.unityIdParent) {
        if (iconMap[appUser.user.unityIdParent.toString()]) {
          const { ReactComponent } = await iconMap[
            appUser.user.unityIdParent.toString()
          ]();
          setIconComponent(() => ReactComponent);
        } else {
          const { ReactComponent } = await iconMap["iconPentracker2"]();
          setIconComponent(() => ReactComponent);
        }
      } else {
        const { ReactComponent } = await iconMap["iconPentracker2"]();
        setIconComponent(() => ReactComponent);
      }
    };

    iconLogo();
  }, []);

  if (!IconComponent) {
    return <div>Loading...</div>;
  }

  return <IconComponent {...rest} />;
};

export default LogoLoader;
