import { FC } from "react";
import { AttendancePair } from "../../../../domain/attendance/Attendance";

type Props = {
  item: AttendancePair;
};

export const AttendanceListRowTypeOfDay: FC<Props> = ({ item }) => {
  const { entry } = item;

  if (entry.typeOfDay === 0) {
    return (
      <div>
        <span className={"text-neutral-400 font-semibold"}>Tipo Trabajo </span>
        <span style={{ color: "green" }}>Regular</span>
      </div>
    );
  } else if (entry.typeOfDay === 1) {
    return (
      <div>
        <span className={"text-neutral-400 font-semibold"}>Tipo Trabajo </span>
        <span style={{ color: "red" }}>Externo</span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="text-neutral-400 font-semibold">Tipo Trabajo </span>
        <span>No Registrado</span>
      </div>
    );
  }
};
