import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { getTimeMilliseconds } from "../../../../util/date";
import { toTime } from "../../../../lib/date";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { toShift } from "../../../../lib/shift";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faSun } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";

export const AttendanceGroupTableShiftCell: FC<CellProps<AttendancePair>> = ({
  rowData,
  ...props
}) => {
  const {
    entry: { timestamp },
  } = rowData!;
  let shift = toShift(timestamp);

  return (
    <Table.Cell {...props}>
      {shift === 0 ? (
        <div>
          <FaIcon icon={faSun} />
          <span> TD DIA</span>
        </div>
      ) : shift === 2 ? (
        <div>
          <FaIcon icon={faMoon} />
          <span> TN NOCHE</span>
        </div>
      ) : shift === 1 ? (
        <div>
          <span>TT DIA</span>
        </div>
      ) : null}
    </Table.Cell>
  );
};
