import { TypeWork, TWProperties } from "../../domain/user/typework";
import { ignoreNilAndPaths, ignorePaths } from "../../util/object";

export type PostTypeWorkDto = {
  id: number;
  label: string;
};

export type GetTypeWorkDto = PostTypeWorkDto;

export type PutTypeWorkDto = Partial<PostTypeWorkDto>;

export function typeworkAsPostDto(typework: TypeWork): PostTypeWorkDto {
  return ignorePaths(typework, TWProperties.reference);
}

export function typeworkAsPutDto(typework: Partial<TypeWork>): PutTypeWorkDto {
  return ignoreNilAndPaths(typework, TWProperties.reference);
}
