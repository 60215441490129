import { Nav, Sidenav } from "rsuite";
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import { ReactComponent as Logo } from "../../../../resources/logo-pentracker2.svg";
// import { ReactComponent as Icon } from "../../../../resources/icon.svg";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faBookBookmark,
  faBoxesStacked,
  faBuilding,
  faCalendar,
  faCalendarDays,
  faCar,
  faChartBar,
  faGear,
  faHistory,
  faHome,
  faNewspaper,
  faPeopleGroup,
  faSignOut,
  faStream,
  faSync,
  faTicket,
  faUserGroup,
  faUserTie,
  faBook,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../../context/UserContext";
import { isNil } from "lodash";
import LogoLoader from "../../../components/logo/logo";
import IconLoader from "../../../components/icon/icon";

export type DashboardMenuProps = {
  expanded?: boolean;
  onTouch?: () => void;
  onToggle?: () => void;
  stylePage: any;
};

const DashboardMenu: FC<DashboardMenuProps> = ({
  expanded = true,
  onTouch,
  onToggle,
  stylePage,
}) => {
  const navigate = useNavigate();
  const keysRef = useRef<string[]>([]);
  const [activeKeys, setActiveKeys] = useState<string>();
  const { requestSignOut, appUser } = useContext(UserAuthContext);
  /* eslint-disable */
  const onSelect = useCallback((eventKey: string) => {
    if (!isNil(eventKey)) {
      navigate(eventKey, {
        preventScrollReset: true,
      });
      if (onTouch) onTouch();
    }
  }, []);
  /* eslint-enable */
  const location = useLocation();

  const addEventKey = useCallback(
    (key: string): string => {
      if (!keysRef.current.includes(key)) keysRef.current.push(key);
      return key;
    },
    [keysRef]
  );

  useEffect(() => {
    const selected = keysRef.current.filter((it) =>
      location.pathname.includes(it)
    );
    setActiveKeys(selected[selected.length - 1]);
  }, [location.pathname, keysRef]);

  return (
    <Sidenav
      expanded={expanded}
      className="h-full overflow-y-auto flex flex-col"
      // appearance="inverse"
      style={stylePage as any}
    >
      <Sidenav.Header className={"flex flex-col"}>
        <div
          className={`w-full flex items-center bg-color-nav ${
            expanded ? "py-5 justify-start pl-5" : "justify-center p-2"
          } `}
        >
          {expanded ? (
            <LogoLoader />
          ) : (
            <IconLoader
              // iconName="iconPentracker2"
              className="w-full h-fit"
            />
          )}
        </div>
      </Sidenav.Header>
      <Sidenav.Body
        className={`flex-1 overflow-y-auto scrollbar-secondary bg-color-nav ${
          expanded ? "pl-2 scrollbar-default" : "p-0 scrollbar-hide"
        }`}
      >
        <Nav
          onSelect={onSelect}
          appearance={"subtle"}
          vertical
          activeKey={activeKeys}
        >
          {/* color={"bg-primary-header"} */}
          <Nav.Item
            eventKey={addEventKey(`/dashboard`)}
            icon={<FaIcon icon={faHome} color={stylePage.iconNavColor} />}
          >
            Descripción General
          </Nav.Item>
          <Nav.Item
            eventKey={addEventKey("/dashboard/personal-info")}
            icon={<FaIcon icon={faUserTie} color={stylePage.iconNavColor} />}
          >
            Perfil
          </Nav.Item>
          {appUser.isManager() ? (
            <Nav.Item
              eventKey={addEventKey("/dashboard/notification-center")}
              icon={<FaIcon icon={faBell} color={stylePage.iconNavColor} />}
            >
              Notificaciones
            </Nav.Item>
          ) : null}
          {/*<Nav.Item*/}
          {/*  eventKey={addEventKey("/dashboard/documentation")}*/}
          {/*  style={itemStyle}*/}
          {/*  icon={<FaIcon icon={faBook} />}*/}
          {/*>*/}
          {/*  Manual de Uso*/}
          {/*</Nav.Item>*/}
          <div className={"w-full h-0.5 bg-white bg-opacity-10"} />

          {expanded ? (
            <>
              {appUser.isClient() ? (
                <Nav.Item
                  panel
                  className={"px-5 py-2 rs-subtitle-text text-xs uppercase"}
                >
                  Agentes
                </Nav.Item>
              ) : (
                <Nav.Item
                  panel
                  className={"px-5 py-2 rs-subtitle-text text-xs uppercase"}
                >
                  Usuarios
                </Nav.Item>
              )}
            </>
          ) : null}

          {appUser.isClient() ? (
            <Nav.Item
              icon={
                <FaIcon icon={faUserGroup} color={stylePage.iconNavColor} />
              }
              eventKey={addEventKey("/dashboard/users")}
            >
              Personal Asignado
            </Nav.Item>
          ) : (
            <Nav.Item
              icon={
                <FaIcon icon={faUserGroup} color={stylePage.iconNavColor} />
              }
              eventKey={addEventKey("/dashboard/users")}
            >
              Usuarios
            </Nav.Item>
          )}

          <Nav.Item
            icon={<FaIcon icon={faBuilding} color={stylePage.iconNavColor} />}
            eventKey={addEventKey("/dashboard/unity")}
          >
            Unidades
          </Nav.Item>

          {expanded ? (
            <Nav.Menu
              // className={"px-5 py-2 text-neutral-300 bg-red-500"}
              icon={<FaIcon icon={faCalendar} color={stylePage.iconNavColor} />}
              eventKey={addEventKey("/dashboard/attendance-group")}
              title={"Asistencia"}
            >
              <Nav.Item
                icon={
                  <FaIcon icon={faChartBar} color={stylePage.iconNavColor} />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/attendance-group")}
              >
                Resúmen
              </Nav.Item>
              <Nav.Item
                icon={
                  <FaIcon
                    icon={faCalendarDays}
                    color={stylePage.iconNavColor}
                  />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/attendance-group/request")}
              >
                Por fecha
              </Nav.Item>
              {/* <Nav.Item
                icon={<FaIcon icon={faStream} color={stylePage.iconNavColor} />}
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/attendance-group/live")}
              >
                En vivo
              </Nav.Item> */}
            </Nav.Menu>
          ) : (
            <Fragment>
              <Nav.Item
                icon={
                  <FaIcon icon={faChartBar} color={stylePage.iconNavColor} />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/attendance-group")}
              >
                Resúmen de Asistencia
              </Nav.Item>
              <Nav.Item
                icon={
                  <FaIcon
                    icon={faCalendarDays}
                    color={stylePage.iconNavColor}
                  />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/attendance-group/request")}
              >
                Asistencia por fecha
              </Nav.Item>
              {/* <Nav.Item
                icon={<FaIcon icon={faStream} color={stylePage.iconNavColor} />}
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/attendance-group/live")}
              >
                Asistencia en vivo
              </Nav.Item> */}
            </Fragment>
          )}

          {expanded ? (
            <Nav.Menu
              icon={<FaIcon icon={faSync} color={stylePage.iconNavColor} />}
              eventKey={addEventKey("/dashboard/patrol-group")}
              title={"Rondas"}
            >
              <Nav.Item
                icon={
                  <FaIcon icon={faChartBar} color={stylePage.iconNavColor} />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/patrol-group")}
              >
                Resúmen
              </Nav.Item>
              <Nav.Item
                icon={
                  <FaIcon
                    icon={faCalendarDays}
                    color={stylePage.iconNavColor}
                  />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/patrol-group/request")}
              >
                Por fecha
              </Nav.Item>
              {/* <Nav.Item
                icon={<FaIcon icon={faStream} color={stylePage.iconNavColor} />}
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/patrol-group/live")}
              >
                En vivo
              </Nav.Item> */}
            </Nav.Menu>
          ) : (
            <Fragment>
              <Nav.Item
                icon={
                  <FaIcon icon={faChartBar} color={stylePage.iconNavColor} />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/patrol-group")}
              >
                Resúmen de Rondas
              </Nav.Item>
              <Nav.Item
                icon={
                  <FaIcon
                    icon={faCalendarDays}
                    color={stylePage.iconNavColor}
                  />
                }
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/patrol-group/request")}
              >
                Rondas por fecha
              </Nav.Item>
              {/* <Nav.Item
                icon={<FaIcon icon={faStream} color={stylePage.iconNavColor} />}
                className={"px-5 py-2 text-neutral-300"}
                eventKey={addEventKey("/dashboard/patrol-group/live")}
              >
                Rondas en vivo
              </Nav.Item> */}
            </Fragment>
          )}
          <Nav.Item
            icon={
              <FaIcon icon={faBookBookmark} color={stylePage.iconNavColor} />
            }
            eventKey={addEventKey("/dashboard/incident-group")}
          >
            Incidencias
          </Nav.Item>

          <div className={"w-full h-0.5 bg-white bg-opacity-10"} />
          {expanded ? (
            <Nav.Item
              panel
              className={"px-5 py-2 rs-subtitle-text text-xs uppercase"}
            >
              Control
            </Nav.Item>
          ) : null}
          <Nav.Item
            icon={
              <FaIcon icon={faPeopleGroup} color={stylePage.iconNavColor} />
            }
            eventKey={addEventKey("/dashboard/visit-control-group")}
          >
            Control de Visitas
          </Nav.Item>
          <Nav.Item
            icon={<FaIcon icon={faCar} color={stylePage.iconNavColor} />}
            eventKey={addEventKey("/dashboard/vehicle-control-group")}
          >
            Control Vehicular
          </Nav.Item>
          <Nav.Item
            icon={
              <FaIcon icon={faBoxesStacked} color={stylePage.iconNavColor} />
            }
            eventKey={addEventKey("/dashboard/goods-control-group")}
          >
            Control de Bienes
          </Nav.Item>

          <div className={"w-full h-0.5 bg-white bg-opacity-10"} />

          {/*  */}

          {
            <>
              {expanded ? (
                <Nav.Item
                  panel
                  className={"px-5 py-2 rs-subtitle-text text-xs uppercase"}
                >
                  Supervisión
                </Nav.Item>
              ) : null}
            </>
          }

          {
            <>
              {expanded ? (
                <Nav.Menu
                  // className={"px-5 py-2 text-neutral-300 bg-red-500"}
                  icon={
                    <FaIcon icon={faCalendar} color={stylePage.iconNavColor} />
                  }
                  eventKey={addEventKey("/dashboard/attendance-group-sup")}
                  title={"Control Rondas"}
                >
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faChartBar}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/attendance-group-sup")}
                  >
                    Resúmen
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faCalendarDays}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/attendance-group-sup/request"
                    )}
                  >
                    Por fecha
                  </Nav.Item>
                  {/* <Nav.Item
                    icon={
                      <FaIcon icon={faStream} color={stylePage.iconNavColor} />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/attendance-group-sup/live"
                    )}
                  >
                    En vivo
                  </Nav.Item> */}
                </Nav.Menu>
              ) : (
                <Fragment>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faChartBar}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/attendance-group-sup")}
                  >
                    Resúmen de Asistencia
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faCalendarDays}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/attendance-group-sup/request"
                    )}
                  >
                    Asistencia por fecha
                  </Nav.Item>
                  {/* <Nav.Item
                    icon={
                      <FaIcon icon={faStream} color={stylePage.iconNavColor} />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/attendance-group-sup/live"
                    )}
                  >
                    Asistencia en vivo
                  </Nav.Item> */}
                </Fragment>
              )}
            </>
          }

          {/*  */}

          {
            <>
              {expanded ? (
                <Nav.Menu
                  // className={"px-5 py-2 text-neutral-300 bg-red-500"}
                  icon={
                    <FaIcon icon={faCalendar} color={stylePage.iconNavColor} />
                  }
                  eventKey={addEventKey("/dashboard/patrol-group-sup")}
                  title={"Rondas Internas Unidad"}
                >
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faChartBar}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/patrol-group-sup")}
                  >
                    Resúmen
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faCalendarDays}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/patrol-group-sup/request"
                    )}
                  >
                    Por fecha
                  </Nav.Item>
                  {/* <Nav.Item
                    icon={
                      <FaIcon icon={faStream} color={stylePage.iconNavColor} />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/patrol-group-sup/live")}
                  >
                    En vivo
                  </Nav.Item> */}
                </Nav.Menu>
              ) : (
                <Fragment>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faChartBar}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/patrol-group-sup")}
                  >
                    Estado de Rondas
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faCalendarDays}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/patrol-group-sup/request"
                    )}
                  >
                    Rondas por fecha
                  </Nav.Item>
                  {/* <Nav.Item
                    icon={
                      <FaIcon icon={faStream} color={stylePage.iconNavColor} />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/patrol-group-sup/live")}
                  >
                    Rondas en vivo
                  </Nav.Item> */}
                </Fragment>
              )}
            </>
          }

          {
            <>
              {expanded ? (
                <Nav.Menu
                  // className={"px-5 py-2 text-neutral-300 bg-red-500"}
                  icon={
                    <FaIcon icon={faCalendar} color={stylePage.iconNavColor} />
                  }
                  eventKey={addEventKey("/dashboard/supervision-unity-group")}
                  title={"Supervisión Unidad"}
                >
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faChartBar}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/supervision-unity-group")}
                  >
                    Resúmen
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faCalendarDays}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/supervision-unity-group/request"
                    )}
                  >
                    Por fecha
                  </Nav.Item>
                  {/* <Nav.Item
                    icon={
                      <FaIcon icon={faStream} color={stylePage.iconNavColor} />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/supervision-unity-group/live"
                    )}
                  >
                    En vivo
                  </Nav.Item> */}
                </Nav.Menu>
              ) : (
                <Fragment>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faChartBar}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey("/dashboard/supervision-unity-group")}
                  >
                    Estado de Rondas
                  </Nav.Item>
                  <Nav.Item
                    icon={
                      <FaIcon
                        icon={faCalendarDays}
                        color={stylePage.iconNavColor}
                      />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/supervision-unity-group/request"
                    )}
                  >
                    Rondas por fecha
                  </Nav.Item>
                  {/* <Nav.Item
                    icon={
                      <FaIcon icon={faStream} color={stylePage.iconNavColor} />
                    }
                    className={"px-5 py-2 text-neutral-300"}
                    eventKey={addEventKey(
                      "/dashboard/supervision-unity-group/live"
                    )}
                  >
                    Rondas en vivo
                  </Nav.Item> */}
                </Fragment>
              )}
            </>
          }

          {/*  */}

          {appUser.isManager() ? (
            <Fragment>
              {expanded ? (
                <Nav.Item
                  panel
                  className={"px-5 py-2 rs-subtitle-text text-xs uppercase"}
                >
                  Cliente
                </Nav.Item>
              ) : null}
              {appUser?.isManager() ? (
                <Nav.Item
                  icon={
                    <FaIcon icon={faTicket} color={stylePage.iconNavColor} />
                  }
                  eventKey={addEventKey("/dashboard/tickets")}
                >
                  Boletas
                </Nav.Item>
              ) : null}
              {appUser?.isManager() ? (
                <Nav.Item
                  icon={
                    <FaIcon icon={faNewspaper} color={stylePage.iconNavColor} />
                  }
                  eventKey={addEventKey("/dashboard/news")}
                >
                  Comunicados
                </Nav.Item>
              ) : null}
              {appUser?.isManager() ? (
                <Nav.Item
                  icon={<FaIcon icon={faGear} color={stylePage.iconNavColor} />}
                  eventKey={addEventKey("/dashboard/role-list")}
                >
                  Cargos
                </Nav.Item>
              ) : null}
              {appUser?.isManager() ? (
                <Nav.Item
                  icon={<FaIcon icon={faGear} color={stylePage.iconNavColor} />}
                  eventKey={addEventKey("/dashboard/shiftlist")}
                >
                  Turnos
                </Nav.Item>
              ) : null}
              {appUser?.isManager() ? (
                <Nav.Item
                  icon={<FaIcon icon={faGear} color={stylePage.iconNavColor} />}
                  eventKey={addEventKey("/dashboard/labels")}
                >
                  Etiquetas
                </Nav.Item>
              ) : null}
              <Nav.Item
                icon={
                  <FaIcon icon={faHistory} color={stylePage.iconNavColor} />
                }
                eventKey={addEventKey("/dashboard/events")}
              >
                Historial de Eventos
              </Nav.Item>
            </Fragment>
          ) : null}
          <div className={"w-full h-0.5 bg-white bg-opacity-10"} />
          {expanded ? (
            <Nav.Item
              panel
              className={"px-5 py-2 rs-subtitle-text text-xs uppercase"}
            >
              Configuración
            </Nav.Item>
          ) : null}
          <Nav.Item
            onClick={requestSignOut}
            icon={<FaIcon icon={faSignOut} color={stylePage.iconNavColor} />}
          >
            Terminar Sesión
          </Nav.Item>
        </Nav>
      </Sidenav.Body>
      <Sidenav.Toggle onToggle={onToggle} />
    </Sidenav>
  );
};

export default DashboardMenu;
