import { DateTime, DateTimeUnit } from "luxon";

export const toShift = (date: Date | number) => {
  let dateTime =
    typeof date === "number"
      ? DateTime.fromMillis(date)
      : DateTime.fromJSDate(date);
  // turno dia
  if (dateTime.hour >= 6 && dateTime.hour < 12) return 0;
  // turno tarde
  if (dateTime.hour >= 12 && dateTime.hour < 18) return 1;
  // turno noche
  return 2;
};
