import { TableColumnRelation } from "../../ui/context/TableContext";

export type AttendanceTableColumns = {
  entry: string;
  departure: string;
  shift: string;
  typeworker: number;
  name: string;
  role: string;
  unity: string;
  observation: string;
  typeofday: string;
  actions: string;
};

export const AttendanceTableSchema: TableColumnRelation<AttendanceTableColumns> =
  {
    large: {
      breakpoints: ["lg", "xl", "2xl"],
      columns: [
        {
          name: "entry",
          label: "Ingreso",
          weight: 0.1,
        },
        {
          name: "departure",
          label: "Salida",
          weight: 0.1,
        },
        {
          name: "typeworker",
          label: "Tipo Trabajador",
          weight: 0.1,
        },
        {
          name: "shift",
          label: "Turno",
          weight: 0.1,
        },
        {
          name: "name",
          label: "Operador",
          weight: 0.3,
        },
        {
          name: "role",
          label: "Rol",
          weight: 0.1,
        },
        {
          name: "unity",
          label: "Unidad",
          weight: 0.3,
        },
        {
          name: "observation",
          label: "Observación",
          weight: 0.1,
        },
        {
          name: "typeofday",
          label: "Tipo de trabajo",
          weight: 0.1,
        },
        {
          name: "actions",
          label: "Acciones",
          weight: 0.1,
        },
      ],
    },
  };
