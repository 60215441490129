import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { FaIcon } from "../../../components/fontawesome/icon";
import {
  faCheckToSlot,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";

export const AttendanceGroupTableTypeDayCell: FC<CellProps<AttendancePair>> = ({
  rowData,
  ...props
}) => {
  const { entry } = rowData!!;
  return (
    <Table.Cell {...props}>
      {entry.typeOfDay === 0 ? (
        <div>
          <span style={{ color: "green" }}>Regular</span>
        </div>
      ) : entry.typeOfDay === 1 ? (
        <div>
          <span style={{ color: "red" }}>Externo</span>
        </div>
      ) : (
        <div>
          <span>No Registrado</span>
        </div>
      )}
    </Table.Cell>
  );
};
