import { FC, useCallback, useContext } from "react";
import { UserAuthContext } from "../../../context/UserContext";
import { ButtonGroup, IconButton, Popover } from "rsuite";
import { UserAvatar } from "../../../components/avatar/useravatar";
import { useNavigate } from "react-router-dom";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faSignOut, faUserTie } from "@fortawesome/free-solid-svg-icons";

// FC<{ whisperRef: any }> = ({ whisperRef })

export const ProfileContainer: FC = () => {
  const { appUser, requestSignOut } = useContext(UserAuthContext);
  const navigate = useNavigate();

  const onManageClicked = useCallback(() => {
    // closeWhisper();
    navigate(`/dashboard/users/${appUser.uid()}/main`);
  }, []);

  const onTileClicked = useCallback(() => {
    // closeWhisper();
    navigate(`/dashboard/personal-info`);
  }, []);

  /*   const closeWhisper = () => {
    if (whisperRef.current) {
      whisperRef.current.close();
    }
  }; */

  return (
    <div className={"rounded-2xl bg-white overflow-hidden flex flex-col p-2"}>
      {appUser ? (
        <div className={"flex flex-col"}>
          <div
            className={
              "flex flex-row items-center justify-center gap-2 overflow-hidden cursor-pointer flex-wrap"
            }
            onClick={onTileClicked}
          >
            <UserAvatar user={appUser.user!} />
            <div className={"flex flex-col leading-none break-all"}>
              <span className={"text-lg font-normal text-black"}>
                {appUser.user!.displayName}
              </span>
              <span className={"text-neutral-500 text-sm"}>
                {appUser.user.email}
              </span>
            </div>
          </div>
          <hr />
          <div className={"w-full flex justify-end"}>
            {/* onManageClicked */}
            {/* requestSignOut */}
            <ButtonGroup>
              <IconButton
                size={"sm"}
                appearance={"ghost"}
                onClick={onManageClicked}
                icon={<FaIcon icon={faUserTie} />}
              >
                Administrar
              </IconButton>
              <IconButton
                size={"sm"}
                onClick={requestSignOut}
                appearance={"ghost"}
                icon={<FaIcon icon={faSignOut} />}
              >
                Cerrar Sesión
              </IconButton>
            </ButtonGroup>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const ProfileSpeaker = (
  <Popover
    style={{
      borderRadius: "20px",
      backgroundColor: "#EFEFEF",
      padding: "5px",
    }}
  >
    <ProfileContainer />
  </Popover>
);
