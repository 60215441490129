import React, { FC, useMemo } from "react";
import { ListChildComponentProps } from "react-window";
import { toRelativeCalendar } from "../../../../../../../lib/date";
import { AttendanceSearchListRowProps } from "./index";
import { RowCell } from "../../../../../../components/row/Cell";
import { AttendanceListRowEntry } from "../../../../../data-display/attendance-list/entry";
import { AttendanceListRowOperator } from "../../../../../data-display/attendance-list/operator";
import { AttendanceListRowObservation } from "../../../../../data-display/attendance-list/observation";
import { AttendanceListRowDeparture } from "../../../../../data-display/attendance-list/departure";
import { AttendanceListRowTypeOfDay } from "../../../../../data-display/attendance-list/typeofday";

export const AttendanceSearchRow: FC<
  ListChildComponentProps<AttendanceSearchListRowProps>
> = ({ index, style, data }) => {
  const { items, onClick } = data;
  const item = items[index];
  //   const wrapper = useMemo(() => {
  //     return new UserWrapper(item);
  //   }, [item]);
  return (
    <div style={style} className={"p-2"}>
      <div
        data-index={index}
        onClick={onClick}
        className={
          "w-full h-full border border-neutral-300 rounded-2xl p-2 flex flex-col justify-center items-start gap-2"
        }
      >
        <div className={"flex w-full flex-col gap-2"}>
          <RowCell label={"UNIDAD"} data={item.entry.unity} dataKey={"label"} />
          <AttendanceListRowTypeOfDay item={item} />
          <AttendanceListRowEntry item={item} />
          <AttendanceListRowDeparture item={item} />
          <AttendanceListRowOperator item={item} />
          <AttendanceListRowObservation item={item} />
        </div>
        {/* <UserRowRole userWrapper={wrapper} />
        <UserRowDisplayName user={item} />
        <UserRowUid user={item} />
        {wrapper.isClient() ? (
          <UserRowClientUnity user={item} />
        ) : (
          <UserRowUnity user={item} />
        )}
        {item.lastSignInTime ? (
          <p className={"m-0 p-0"}>
            <span className={"mr-2 text-neutral-400 font-semibold"}>
              ULT. SESIÓN
            </span>
            <span>{toRelativeCalendar(new Date(item.lastSignInTime))}</span>
          </p>
        ) : null}
        <p className={"m-0 p-0"}>
          <span className={"mr-2 text-neutral-400 font-semibold"}>CREADO</span>
          <span>{toRelativeCalendar(new Date(item.creationTime))}</span>
        </p> */}
      </div>
    </div>
  );
};
