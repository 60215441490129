import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback, useContext } from "react";
import { Button, Modal } from "rsuite";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { UserAuthContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

export const SignOutModal: FC<any> = () => {
  const navigate = useNavigate();
  const { signOutFlag, revokeSignOutRequest, signOut } =
    useContext(UserAuthContext);
  /*eslint-disable*/
  const signOutNow = useCallback(async () => {
    if (signOut) await signOut();
    navigate("/auth");
  }, []);
  /*eslint-enable*/
  return signOutFlag === true ? (
    <Modal
      open={true}
      onClose={revokeSignOutRequest}
      className="absolute left-1 transform -translate-x-1/2 z-[1000]"
    >
      <Modal.Header>Cerrar Sesión</Modal.Header>
      <Modal.Body>
        ¿ Confirma que cerrará la sesión en este dispositivo ?
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="ghost" onClick={signOutNow}>
          <FontAwesomeIcon icon={faRightFromBracket} /> Confirmar
        </Button>
        <Button appearance="subtle" onClick={revokeSignOutRequest}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};
