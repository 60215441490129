import { db } from "../db";
import { TypeWork } from "../../../domain/user/typework";

export class TypeWorkDao {
  static async putTypeWork(...typework: TypeWork[]) {
    await db.typeworks.bulkPut(typework);
  }

  static async getTypeWorkById(id: number) {
    return db.typeworks.where("id").equals(id).first();
  }

  static async clear() {
    await db.typeworks.clear();
  }

  static async delete(reference: string) {
    await db.typeworks.delete(reference);
  }

  static async update(reference: string, newData: Partial<TypeWork>) {
    await db.typeworks.update(reference, newData);
  }

  static async getList() {
    return db.typeworks.toArray();
  }
}
