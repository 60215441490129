import { NewUser } from "../../domain/user/User";
import { User as FirebaseUser } from "firebase/auth";

export type UserDto = {
  uid: string;
  email?: string;
  emailVerified: boolean;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
  disabled: boolean;
  creationTime: string;
  lastSignInTime: string;
  roleId?: number | null;
  shiftId?: number | null;
  unityId?: number | null;
  unityIdParent?: number | null;
  accountTypeId?: number | null;
  typeWorker?: number | null;
  clientUnity?: Array<number>;
};

export type UserListQueryRequest = {
  orderBy:
    | keyof UserDto
    | `${keyof FirebaseUser["metadata"]}`
    | "customClaims.role";
  order: "asc" | "desc";
};
export type NewUserDto = {
  uid: string;
  displayName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  photoUrl?: string | null;
  shiftId?: number | null;
  unityId?: number | null;
  accountTypeId?: number | null;
  typeWorker?: number | null;
  roleId?: number | null;
  password?: string;
  clientUnity?: Array<number>;
};

export function newUserAsDto(newUser: NewUser): NewUserDto {
  const copy: NewUser = { ...newUser };
  delete copy["photoFileType"];
  return copy;
}

export function newUserPartialAsDto(
  newUser: Partial<NewUser>
): Partial<NewUserDto> {
  const copy = {
    ...newUser,
  };
  delete copy["photoFileType"];
  delete copy["reason"];
  return copy;
}
