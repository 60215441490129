import { FirestoreSimpleCrudSource } from "../source/FirestoreSimpleCrudSource";
import {
  GetTypeWorkDto,
  PostTypeWorkDto,
  PutTypeWorkDto,
  typeworkAsPutDto,
} from "../../network/user/TypeWork";
import {
  NewTypeWork,
  TypeWork,
  typeworkDtoAsDomain,
} from "../../domain/user/typework";
import { where } from "firebase/firestore";
import { TypeWorkDao } from "../database/dao/TypeWork";
import { isEmpty, toNumber } from "lodash";

export default class TYpeWorkRepository {
  static firestoreRepository = new FirestoreSimpleCrudSource<
    GetTypeWorkDto,
    PostTypeWorkDto,
    PutTypeWorkDto
  >("typework");

  static async create(object: NewTypeWork) {
    if (!object.id) object.id = Date.now();
    else object.id = toNumber(object.id);
    const reference = await this.firestoreRepository.create(object, object.id);
    await TypeWorkDao.putTypeWork({
      ...object,
      reference: reference.path,
    });
  }

  static async delete(reference: string): Promise<void> {
    await this.firestoreRepository.delete(reference);
    await TypeWorkDao.delete(reference);
  }

  static async getList(forced: boolean = false) {
    const localList = await TypeWorkDao.getList();
    if (isEmpty(localList) || forced) {
      const firestoreList = await this.firestoreRepository.getList(null);
      console.log("firestoreList", firestoreList);
      await TypeWorkDao.clear();
      await TypeWorkDao.putTypeWork(
        ...firestoreList.map((typeworkDto) =>
          typeworkDtoAsDomain(typeworkDto.data, typeworkDto.reference)
        )
      );
      return TypeWorkDao.getList();
    } else {
      return localList;
    }
  }

  static async fetchList(forced: boolean = false) {
    console.log("firestoreList");
    const localList = await TypeWorkDao.getList();
    console.log("firestoreList1");
    if (isEmpty(localList) || forced) {
      console.log("firestoreList2");
      const firestoreList = await this.firestoreRepository.getList(null);
      console.log("firestoreList", firestoreList);
      await TypeWorkDao.clear();
      await TypeWorkDao.putTypeWork(
        ...firestoreList.map((typeworkDto) =>
          typeworkDtoAsDomain(typeworkDto.data, typeworkDto.reference)
        )
      );
    }
  }

  static async update(
    reference: string,
    newData: Partial<TypeWork>
  ): Promise<void> {
    await this.firestoreRepository.update(reference, typeworkAsPutDto(newData));
    await TypeWorkDao.update(reference, newData);
  }

  static async getTypeWork(id: number, forcedRefresh: boolean = false) {
    const localTypeWork = await TypeWorkDao.getTypeWorkById(id);
    if (!localTypeWork || forcedRefresh) {
      const firestoreTypeWork = await this.firestoreRepository.getByQuery([
        where("id", "==", id),
      ]);
      if (firestoreTypeWork) {
        await TypeWorkDao.putTypeWork(
          typeworkDtoAsDomain(
            firestoreTypeWork.data,
            firestoreTypeWork.reference
          )
        );
      }
      return TypeWorkDao.getTypeWorkById(id);
    } else {
      return localTypeWork;
    }
  }

  static async fetchTypeWork(id: number, forcedRefresh: boolean = false) {
    const localTypeWork = await TypeWorkDao.getTypeWorkById(id);

    if (!localTypeWork || forcedRefresh) {
      const firestoreTypeWork = await this.firestoreRepository.getByQuery([
        where("id", "==", id),
      ]);
      if (firestoreTypeWork) {
        await TypeWorkDao.putTypeWork(
          typeworkDtoAsDomain(
            firestoreTypeWork.data,
            firestoreTypeWork.reference
          )
        );
      }
    }
  }
}
