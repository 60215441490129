import { InputDataList } from "../../../../components/modal/FormModal";
import {
  CharacterLock,
  Email,
  Phone,
  UserBadge,
  UserInfo,
} from "@rsuite/icons";
import { NewUserUploadPhotoInput } from "../components/uploadphotoinput";
import { Schema } from "rsuite";
import { NewUser } from "../../../../../domain/user/User";
import { NewUserRoleSelectInput } from "./components/roleselect";
import { NewUserShiftSelectInput } from "./components/shiftselect";
import { NewUserAccountTypeSelectInput } from "./components/typeselect";
import { NewUserClientUnitySelectInput } from "./components/clientunityselect";
import { NewUserUnitySelectInput } from "./components/operatorunityselect";
import { NewUserUnityParentSelectInput } from "./components/clientUnityParentSelect";
import { ReasonInputSelector } from "../../../../components/input/ReasonSelector";
import { isNotBlank } from "../../../../../util/validation";
import { NewUserTypeWorkerSelectInput } from "./components/typeWorkerSelect";

export const NewUserInputSchema = Schema.Model<NewUser>({
  uid: Schema.Types.StringType()
    .minLength(8)
    .isRequired("Complete este campo."),
  displayName: Schema.Types.StringType().isRequired("Complete este campo."),
  password: Schema.Types.StringType()
    .isRequired("Complete este campo.")
    .minLength(8),
  phoneNumber: Schema.Types.StringType().pattern(
    new RegExp(/^\+51\d{9}$/),
    "Ingrese un número de teléfono válido."
  ),
  email: Schema.Types.StringType()
    .isRequired("Complete este campo")
    .isEmail("Ingrese un email válido."),
  reason: Schema.Types.StringType()
    .isRequired("Es necesario completar este campo.")
    .addRule(isNotBlank(10), "El nombre debe contener al menos 10 caracteres."),
});

export const NewUserInputDataList: InputDataList<NewUser> = [
  {
    label: "ID",
    name: "uid",
    type: "text",
    addon: <UserBadge />,
    defaultValue: "",
  },
  {
    label: "Apellidos y Nombres",
    name: "displayName",
    type: "text",
    addon: <UserInfo />,
    defaultValue: "",
  },
  {
    label: "Nro. Teléfono / Designado",
    name: "phoneNumber",
    type: "text",
    addon: <Phone />,
    defaultValue: "",
  },
  {
    label: "Correo Electrónico",
    name: "email",
    type: "text",
    addon: <Email />,
    defaultValue: "",
  },
  {
    label: "Contraseña",
    name: "password",
    type: "text",
    addon: <CharacterLock />,
    defaultValue: "",
  },
  {
    name: "roleId",
    type: "custom",
    provide: NewUserRoleSelectInput,
    defaultValue: null,
  },
  {
    name: "shiftId",
    type: "custom",
    defaultValue: null,
    provide: NewUserShiftSelectInput,
  },
  {
    name: "accountTypeId",
    type: "custom",
    defaultValue: null,
    provide: NewUserAccountTypeSelectInput,
  },
  /*   {
    name: "typeWorker",
    type: "custom",
    provide: NewUserTypeWorkerSelectInput,
    defaultValue: null,
  }, */
  {
    name: "unityId",
    type: "custom",
    provide: NewUserUnitySelectInput,
    defaultValue: null,
  },
  {
    name: "unityIdParent",
    type: "custom",
    provide: NewUserUnityParentSelectInput,
    defaultValue: null,
  },
  {
    label: "Foto de perfil",
    name: "photoFileType",
    type: "custom",
    provide: NewUserUploadPhotoInput,
    defaultValue: null,
  },
  {
    name: "clientUnity",
    type: "custom",
    defaultValue: undefined,
    provide: NewUserClientUnitySelectInput,
  },
  {
    name: "reason",
    label: "Motivo de cambio",
    type: "custom",
    help: "Detalle el motivo de la eliminación",
    defaultValue: undefined,
    provide: (props) => (
      <ReasonInputSelector
        {...(props as any)}
        options={["Nuevo cliente", "Adición de cliente existente"]}
      />
    ),
  },
];
