import { FC, useState, useEffect } from "react";
import { CellProps, Table } from "rsuite";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { TypeWorkDao } from "../../../../data/database/dao/TypeWork";
import { TWProperties, TypeWork } from "../../../../domain/user/typework";

export const AttendanceGroupTableTypeWorkerCell: FC<
  CellProps<AttendancePair>
> = ({ rowData, ...props }) => {
  return (
    <Table.Cell {...props}>
      {rowData?.entry.owner?.accountType ? (
        <div>{rowData?.entry.owner?.accountType?.label}</div>
      ) : (
        <div>
          <span>...</span>
        </div>
      )}
    </Table.Cell>
  );
};
