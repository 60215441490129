import { useState } from "react";
import { Response } from "../../domain/app/Response";
import RoleRepository from "../../data/repository/RoleRepository";
import { NewRole } from "../../domain/user/Role";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../data/database/db";
import { LocalErrorLogRepository } from "../../data/repository/LocalErrorLogRepository";
import { UserWrapper } from "../../domain/user/User";

export function useUserRoleViewModel(currentUser: UserWrapper) {
  const roles = useLiveQuery(() => db.roles.toArray());
  const allowedRoles = useLiveQuery(() =>
    db.roles.where("id").above(currentUser.user.roleId).toArray()
  );
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [addEvent, setAddEvent] = useState<boolean | null>(null);
  const [refreshEvent, setRefreshEvent] = useState<boolean | null>(null);
  const [deleteEvent, setDeleteEvent] = useState<
    | {
        label: string;
        reference: string;
      }[]
    | null
  >(null);
  const [fetchState, setFetchState] = useState<Response<boolean> | null>(null);
  const [postState, setCreateState] = useState<Response<boolean> | null>(null);
  const [deleteState, setDeleteState] = useState<Response<boolean> | null>(
    null
  );

  async function fetchList(forceRefresh: boolean = false) {
    if (fetchState?.loading) return;
    setFetchState(Response.loading());
    try {
      if (fetchState?.loading) return;
      await RoleRepository.fetchList(forceRefresh);
      setFetchState(Response.success(true));
    } catch (err: any) {
      await LocalErrorLogRepository.registerError(
        "Error al obtener lista de cargos",
        err
      );
      setFetchState(Response.failure(err));
    }
  }

  function onListStateReceived() {
    setFetchState(null);
  }

  function requestAddEvent() {
    setAddEvent(true);
  }

  function onAddEventCompleted() {
    setAddEvent(null);
  }

  function requestRefreshEvent() {
    setRefreshEvent(true);
  }

  function onRefreshEventCompleted() {
    setRefreshEvent(null);
  }

  const allLoadingObserver =
    (!!fetchState && fetchState.loading) ||
    (!!postState && postState.loading) ||
    (!!deleteState && deleteState.loading);

  async function postRole(role: NewRole) {
    setCreateState(Response.loading());
    try {
      await RoleRepository.create(role);
      setCreateState(Response.success(true));
    } catch (e: any) {
      await LocalErrorLogRepository.registerError(
        `Error al intentar añadir cargo con id: '${role.id}' nombre: '${role.label}'`,
        e
      );
      setCreateState(Response.failure(e));
    }
  }

  function onPostStateReceived() {
    setCreateState(null);
  }

  async function requestDeleteEvent(
    referencesToDelete: Array<string> = selectedItems
  ) {
    if (!referencesToDelete || referencesToDelete.length <= 0) {
      setDeleteState(
        Response.successWithWarning("No se seleccionaron cargos para eliminar.")
      );
      return;
    }
    const toDelete: { label: string; reference: string }[] = [];
    const tasks = referencesToDelete.map(async (it) => {
      const role = await db.roles.get(it);
      toDelete.push(
        role
          ? { label: role.label, reference: role.reference }
          : { label: it, reference: it }
      );
    });
    await Promise.all(tasks);
    setDeleteEvent(toDelete);
  }

  function onDeleteEventCompleted() {
    setDeleteEvent(null);
  }

  async function deleteRole() {
    setDeleteState(Response.loading());
    const toDelete = deleteEvent;
    if (!toDelete || toDelete.length <= 0) {
      setDeleteState(
        Response.failure(new Error("No se seleccionaron cargos para eliminar."))
      );
      return;
    }
    let errorCount = 0;
    const tasks = toDelete.map(async (it) => {
      const { reference, label } = it;
      try {
        await RoleRepository.delete(reference);
      } catch (e: any) {
        errorCount += 1;
        const errorLabel = `Ocurrió un error al eliminar el cargo con id: '${label}'`;
        await LocalErrorLogRepository.registerError(errorLabel, e);
      }
    });
    await Promise.all(tasks);
    if (errorCount === toDelete.length) {
      setDeleteState(
        Response.failure(
          new Error(
            "Ocurrió un error al eliminar los cargos seleccionados (0 eliminados)."
          )
        )
      );
    } else if (errorCount > 0) {
      clearSelection();
      setDeleteState(
        Response.successWithWarning(
          `Algunos cargos no pudieron eliminarse. (${
            toDelete.length - errorCount
          } eliminado(s))`
        )
      );
    } else {
      clearSelection();
      setDeleteState(Response.success(true));
    }
  }

  function onDeleteStateReceived() {
    setDeleteState(null);
  }

  function addItemSelection(reference: string) {
    const existingIndex = selectedItems.indexOf(reference);
    if (existingIndex === -1) {
      setSelectedItems((current) => {
        const copy = [...current];
        copy.push(reference);
        return copy;
      });
    }
  }

  function removeItemSelection(reference: string) {
    const existingIndex = selectedItems.indexOf(reference);
    if (existingIndex > -1) {
      setSelectedItems((current) => {
        const copy = [...current];
        copy.splice(existingIndex, 1);
        return copy;
      });
    }
  }

  function switchItemSelection(reference: string) {
    const existingIndex = selectedItems.indexOf(reference);
    if (existingIndex === -1) {
      addItemSelection(reference);
    } else {
      removeItemSelection(reference);
    }
  }

  function switchSelectAll() {
    const currentRoles = roles;
    if (!!currentRoles && currentRoles.length !== selectedItems.length) {
      setSelectedItems(currentRoles.map((it) => it.reference));
    } else {
      clearSelection();
    }
  }

  function clearSelection() {
    setSelectedItems([]);
  }

  return {
    roles,
    fetchState,
    fetchList,
    onListStateReceived,
    addEvent,
    refreshEvent,
    requestAddEvent,
    onAddEventCompleted,
    requestRefreshEvent,
    onRefreshEventCompleted,
    postState,
    postRole,
    onPostStateReceived,
    allLoadingObserver,
    requestDeleteEvent,
    onDeleteEventCompleted,
    deleteRole,
    onDeleteStateReceived,
    deleteEvent,
    deleteState,
    selectedItems,
    switchItemSelection,
    switchSelectAll,
    clearSelection,
    allowedRoles,
  };
}
