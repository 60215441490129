import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { toTime } from "../../../../lib/date";
import { UserSupervision } from "../../../../domain/supervision/Supervision";
import { FaIcon } from "../../../components/fontawesome/icon";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";

export const SupervisionGroupTableDepartureCell: FC<
  CellProps<UserSupervision>
> = ({ rowData, ...props }) => {
  const { value } = rowData?.formDataPageMain.controlDeparture!;

  if (!!value) {
    return <Table.Cell {...props}>{value}</Table.Cell>;
  } else {
    return (
      <Table.Cell {...props}>
        <div className={"flex gap-1 justify-center items-center"}>
          <FaIcon icon={faUserClock} />
          <span className={"italic"}>Pendiente</span>
        </div>
      </Table.Cell>
    );
  }
};
