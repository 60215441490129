import { GetTypeWorkDto } from "../../network/user/TypeWork";

export enum TWProperties {
  reference = "reference",
  id = "id",
  label = "label",
  export = "export",
}

export type TypeWork = {
  [TWProperties.reference]: string;
  [TWProperties.id]: number;
  [TWProperties.label]: string;
};

export type NewTypeWork = Omit<TypeWork, TWProperties.reference>;

export function typeworkDtoAsDomain(
  typeworkDto: GetTypeWorkDto,
  reference: string
): TypeWork {
  return { ...typeworkDto, reference: reference };
}

export class UserRoles {
  static titular = 0;
  static reten = 1;
  static descansero = 2;
}
