import React, { FC, Fragment } from "react";
import { PentrackerUser } from "../../../../domain/user/User";
import { Link } from "react-router-dom";

type Props = {
  user: PentrackerUser;
};

export const UserRowClientUnity: FC<Props> = ({ user }) => {
  const { clientUnityArray } = user;
  let hasMoreThanThree = false;

  if (clientUnityArray === undefined) {
    hasMoreThanThree = false;
  } else if (clientUnityArray.length > 3) {
    hasMoreThanThree = true;
  }

  return (
    <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
      <span className={"text-neutral-400 font-semibold"}>
        UNIDADES DEL CLIENTE
      </span>
      {user.clientUnityArray ? (
        <div className={"flex flex-row flex-wrap gap-2"}>
          {hasMoreThanThree ? (
            <Fragment>
              {user.clientUnityArray.map((unity) => (
                <Link
                  key={`client-unity-${unity.id}`}
                  to={`/dashboard/unity/${unity.id}`}
                >
                  {unity.label}
                </Link>
              ))}
              <Link to={`/dashboard/users/${user.uid}`}>...más</Link>
            </Fragment>
          ) : (
            user.clientUnityArray.map((unity) => (
              <Link
                key={`client-unity-${unity.id}`}
                to={`/dashboard/unity/${unity.id}`}
              >
                {unity.label}
              </Link>
            ))
          )}
        </div>
      ) : (
        <span>No se asignaron unidades al cliente</span>
      )}
    </div>
  );
};
