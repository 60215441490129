import { GetSupervisionDto } from "../../network/supervision/Supervision";
// import {Unity} from "../unity";
// import {Zone} from "./Zone";
import { PentrackerUser } from "../user/User";

export type Supervision = Omit<GetSupervisionDto, "timestamp"> & {
  reference: string;
  timestamp: number;
};

export type UserSupervision = Omit<GetSupervisionDto, "timestamp"> & {
  reference: string;
  // unity?: Unity
  // zone?: Zone
  //owner?: PentrackerUser;
  timestamp: number;
};

export const supervisionDtoAsDomain = (
  supervisionDto: GetSupervisionDto,
  reference: string
): Supervision => {
  return {
    ...supervisionDto,
    reference,
    timestamp: supervisionDto.timestamp.toDate().getTime(),
  };
};

export const supervisionAsUserSupervision = (
  supervision: Supervision
  //   unity?: Unity,
  //   zone?: Zone,
  //   owner?: PentrackerUser
): UserSupervision => {
  return {
    ...supervision,
    // unity,
    // zone,
    // owner,
  };
};
