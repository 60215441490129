import { log } from "console";

const logoMap: {
  [key: string]: () => Promise<{
    ReactComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  }>;
} = {
  logoPentracker2: () =>
    import("../../../resources/logos/logo-pentracker2.svg"),
  3505875964657665: () =>
    import("../../../resources/logos/3505875964657665.svg"),
  1683263204338: () => import("../../../resources/logos/1683263204338.svg"),
  1683266447539: () => import("../../../resources/logos/1683266447539.svg"),
  3498475681480704: () =>
    import("../../../resources/logos/3498475681480704.svg"),
  1683265682785: () => import("../../../resources/logos/1683265682785.svg"),
  1683265777496: () => import("../../../resources/logos/1683265777496.svg"),
  1683266456572: () => import("../../../resources/logos/1683266456572.svg"),
  1707510634020: () => import("../../../resources/logos/1707510634020.svg"),
  1709676524556: () => import("../../../resources/logos/1709676524556.svg"),
  1691542450511: () => import("../../../resources/logos/1691542450511.svg"),
  1694636297920: () => import("../../../resources/logos/1694636297920.svg"),
  default: () => import("../../../resources/logos/logo-pentracker2.svg"),
  // : () =>
  //   import("../../../resources/logos/.svg"),
};

/* const getLogo = (key: string) => {
  return (logoMap[key] || logoMap.default)();
};
 */
export default logoMap;

// ABRIL 3505875964657665 -
// ALDEAS 1683263204338 -
// CAJA TRUJILLO 1683266447539 -
// LA PARCELA 3498475681480704 -
// COSTAMAR 1683265682785 -
// EFILA 1683265777496 -
// INVERSIONES EL PINO 1683266456572 -
// LADRILLOS PIRAMIDE 1707510634020 -
// PRIMA 1709676524556 -
// QUIMICA ANDINA 1691542450511 -
// SAMI 1694636297920 -
// TRANSPORTES PLUTON 1707262396216
