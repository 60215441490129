import React, {
  FC,
  Fragment,
  MutableRefObject,
  useCallback,
  useContext,
  useRef,
} from "react";
import { Pagination, Table } from "rsuite";
import { useTableSize } from "../../../../hook/width";
import { TableInstance } from "rsuite/Table";
// import { TableHeaderStyle } from "../../../components/table/style";
import { TableColumnContext } from "../../../context/TableContext";
import { SupervisionGroupTableTimeCell } from "./time";
import { SupervisionGroupTableLiableCell } from "./liable";
import { SupervisionGroupTableUnityCell } from "./unity";
import { SupervisionGroupTableEntryCell } from "./entry";
import { SupervisionGroupTableDepartureCell } from "./departure";

import { UserSupervision } from "../../../../domain/supervision/Supervision";

import { EmptyState } from "../../../components/state/empty";
import { useDataPager } from "../../../../hook/pager";
import { SupervisionTableColumns } from "../../../../schema/table/SupervisionTable";

import { useStyleContext } from "../../../context/StyleContext";
import { SupervisionGroupTableActionCell } from "./actions";

type Props = {
  userSupervisionList?: UserSupervision[];
  onSelect: (item: UserSupervision) => void;
  onPreviewClicked: (item: UserSupervision) => void;
  name: string;
};

export const SupervisionTable: FC<Props> = ({
  userSupervisionList,
  onSelect,
  onPreviewClicked,
  name,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;
  const tableRef = useRef<TableInstance<any, any>>(null);
  const tableSize = useTableSize(tableRef);
  const tableContext = useContext(TableColumnContext);
  const tablePreferences =
    tableContext.getTablePreferences<SupervisionTableColumns>(name);

  const {
    tableHeaderStyle,
    tableHeaderStyleSecondary,
    stylePage,
    primaryColors,
  } = useStyleContext();

  /* eslint-disable */
  const { partialData, changePage, page, changeLimit, limits, limit } =
    useDataPager({
      id: `pat_table_${name}`,
      items: userSupervisionList,
    });

  const onRowSelect = useCallback(
    (item: UserSupervision) => {
      if (actionRef.current) onPreviewClicked(item);
      else onSelect(item);
      actionRef.current = null;
    },
    [partialData]
  );

  const onPreviewActionSelected = useCallback(() => {
    actionRef.current = "preview";
  }, [partialData]);

  /* eslint-enable */
  return (
    <Fragment>
      <Table
        autoHeight
        data={partialData}
        ref={tableRef}
        onRowClick={onRowSelect as any}
        renderEmpty={() => (
          <EmptyState
            title={"No hay supervisiones"}
            message={
              "No se encontraron reportes de supervisión para la fecha seleccionada."
            }
          />
        )}
        className={"rounded md:rounded-xl"}
        cellBordered
      >
        {tablePreferences.map((column) => {
          switch (column.name) {
            case "time":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    Hora Creada
                  </Table.HeaderCell>
                  <SupervisionGroupTableTimeCell />
                </Table.Column>
              );
            case "entry":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    Entrada
                  </Table.HeaderCell>
                  <SupervisionGroupTableEntryCell />
                </Table.Column>
              );
            case "liable":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    Responsable
                  </Table.HeaderCell>
                  <SupervisionGroupTableLiableCell />
                </Table.Column>
              );
            case "unity":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    Unidad
                  </Table.HeaderCell>
                  <SupervisionGroupTableUnityCell />
                </Table.Column>
              );
            case "departure":
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    Salida
                  </Table.HeaderCell>
                  <SupervisionGroupTableDepartureCell />
                </Table.Column>
              );
            default:
              return (
                <Table.Column
                  resizable
                  align={"center"}
                  verticalAlign={"middle"}
                  width={tableSize.calculateWeight(column.weight)}
                  key={`columns-${column.name}`}
                >
                  <Table.HeaderCell style={tableHeaderStyle}>
                    Acciones
                  </Table.HeaderCell>
                  <SupervisionGroupTableActionCell
                    onPreviewActionClicked={onPreviewActionSelected}
                  />
                </Table.Column>
              );
          }
        })}
      </Table>
      {userSupervisionList ? (
        <div className={"py-4 flex flex-col gap-4"}>
          <span className={"text-md"}>
            Mostrando {partialData?.length} elementos / Total{" "}
            {userSupervisionList.length}
          </span>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={["limit", "|", "pager", "skip"]}
            total={userSupervisionList.length}
            limitOptions={limits}
            limit={limit}
            activePage={page}
            onChangePage={changePage}
            onChangeLimit={changeLimit}
          />
        </div>
      ) : null}
    </Fragment>
  );
};
