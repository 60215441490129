import { FC } from "react";
import { CellProps, Table } from "rsuite";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { Link } from "react-router-dom";

export const AttendanceGroupTableRoleCell: FC<CellProps<AttendancePair>> = ({
  rowData,
  ...props
}) => {
  const { entry } = rowData!;
  return (
    <Table.Cell {...props}>
      {entry.owner?.userRole ? entry.owner.userRole.label : entry.ownerUid}
    </Table.Cell>
  );
};
