import React, { FC } from "react";

type Props = {
  label: string;
  data: any;
  dataKey: string;
};

export const RowCell: FC<Props> = ({ label, data, dataKey }) => {
  return (
    <div className={"m-0 p-0 flex flex-row flex-wrap gap-2"}>
      <span className={"text-neutral-400 font-semibold"}>{label}</span>
      <span>{data[dataKey]}</span>
    </div>
  );
};
