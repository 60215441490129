import React, { FC, MutableRefObject, useCallback, useRef } from "react";
import { EmptyState } from "../../../components/state/empty";
import { AttendancePair } from "../../../../domain/attendance/Attendance";
import { AttendanceRow } from "./row";
import { Pagination } from "rsuite";
import { useDataPager } from "../../../../hook/pager";

type AttendanceListProps = {
  name?: string;
  attendanceList?: AttendancePair[];
  onPreviewClicked: (item: AttendancePair) => void;
  onSelect: (item: AttendancePair) => void;
};

export const AttendanceList: FC<AttendanceListProps> = ({
  attendanceList,
  onPreviewClicked,
  onSelect,
  name,
}) => {
  const actionRef = useRef<string | null>(null) as MutableRefObject<
    string | null
  >;

  const { limits, changeLimit, limit, page, partialData, changePage } =
    useDataPager<AttendancePair>({
      id: `att_list_${name}`,
      items: attendanceList,
    });
  /* eslint-disable */
  const onPreviewRowClicked = useCallback<
    React.MouseEventHandler<HTMLElement>
  >(() => {
    actionRef.current = "preview";
  }, [partialData]);

  const onSelectRow = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const index = event.currentTarget.dataset["index"] as unknown as number;
      if (partialData) {
        const item = partialData[index];
        if (item) {
          if (actionRef.current === "preview") {
            onPreviewClicked(item);
          } else {
            onSelect(item);
          }
        }
      }
      actionRef.current = null;
    },
    [partialData]
  );

  /* eslint-enable */

  return attendanceList && attendanceList.length > 0 ? (
    <div className={"w-full h-auto"}>
      {partialData?.map((it, i) => (
        <AttendanceRow
          index={i}
          style={{}}
          data={{
            attendanceList: partialData,
            onSelect: onSelectRow,
            onPreviewClicked: onPreviewRowClicked,
          }}
          key={`attendance-row-${it.entry.ownerUid}`}
        />
      ))}
      <div className={"py-4 flex flex-col gap-4"}>
        <span className={"text-md"}>
          Mostrando {partialData?.length} elementos / Total{" "}
          {attendanceList.length}
        </span>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["limit", "pager"]}
          total={attendanceList.length}
          limitOptions={limits}
          limit={limit}
          activePage={page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>
    </div>
  ) : (
    <EmptyState
      title={"Sin reportes de asistencia"}
      message={
        "No se encontraron reportes de asistencia para la fecha seleccionada."
      }
    />
  );
};
