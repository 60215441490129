import { TableColumnRelation } from "../../ui/context/TableContext";

export type SupervisionTableColumns = {
  time: string;
  entry: string;
  liable: string;
  unity: string;
  departure: string;
  actions: string;
};

export const SupervisionTableSchema: TableColumnRelation<SupervisionTableColumns> =
  {
    large: {
      breakpoints: ["lg", "xl", "2xl"],
      columns: [
        {
          name: "time",
          label: "Fecha Creación",
          weight: 0.1,
        },
        {
          name: "entry",
          label: "Entrada",
          weight: 0.1,
        },
        {
          name: "liable",
          label: "Responsable",
          weight: 0.3,
        },
        {
          name: "unity",
          label: "Unidad",
          weight: 0.3,
        },
        {
          name: "departure",
          label: "Salida",
          weight: 0.1,
        },
        {
          name: "actions",
          label: "Acciones",
          weight: 0.1,
        },
      ],
    },
  };
