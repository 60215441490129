import { FC, useEffect } from "react";
import { CustomInputProps } from "../../../../../components/modal/FormModal";
import { NewUser } from "../../../../../../domain/user/User";
import { Loader, SelectPicker } from "rsuite";
import { toNumber } from "lodash";
import { UserRoles } from "../../../../../../domain/user/Role";
import useAccountTypeViewModel from "../../../../../../viewmodel/type/AccountType";

const Component: FC<CustomInputProps<NewUser>> = ({
  name,
  updateValue,
  values,
}) => {
  const viewModel = useAccountTypeViewModel();

  /* eslint-disable */
  useEffect(() => {
    void viewModel.fetchList();
  }, []);

  useEffect(() => {
    if (viewModel.fetchState && !viewModel.fetchState.loading) {
      if (viewModel.fetchState.isFailed()) {
        window.toast.error(
          "Ocurrió un error al obtener la lista de etiquetas del operador."
        );
      }
    }
  }, [viewModel.fetchState]);
  /* eslint-enable */
  return (
    <div className={"flex flex-col gap-1"}>
      <p>Etiqueta de Operador</p>
      {viewModel.fetchState?.loading ? (
        <Loader content={"Cargando..."} />
      ) : (
        <SelectPicker
          block
          data={viewModel.types || []}
          onSelect={(selection) => {
            updateValue(name, toNumber(selection));
          }}
          onClean={() => {
            updateValue(name, null);
          }}
          labelKey={"label"}
          valueKey={"id"}
          value={values[name]}
          cleanable
        />
      )}
    </div>
  );
};

export const NewUserAccountTypeSelectInput: FC<CustomInputProps<NewUser>> = (
  props
) => {
  if (
    [UserRoles.groupboss, UserRoles.CCTV, UserRoles.agent].includes(
      props.values.roleId
    )
  ) {
    return <Component {...props} />;
  }
  return null;
};
